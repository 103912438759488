@import "./../../assets/css/index.scss";

.contactcontainer {
  border-top: 1px solid rgb(109, 109, 109);
  border-bottom: 1px solid rgb(109, 109, 109);
  background-color: $bodycolor;
  color: white;
  padding-top: 5rem;
  padding-bottom: 5rem;

  label {
    font-weight: 600;
  }
}
