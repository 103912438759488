@import "./mixins.scss";
@import "./variables.scss";
@import './globals.scss';

@import '~bootstrap/scss/bootstrap';

.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-color, #ffffff);
}

a {
  color: white;
  text-decoration: none;
  font-weight: 800;
  font-family: 'SFProDisplay-Bold', inherit;
}

.grecaptcha-badge { 
  visibility: hidden;
}