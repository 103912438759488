@import "./../../assets/css/index.scss";

.footer {
  background-color: $bodycolor;
  padding: 2rem;

  .logo {
    opacity: 0.5;
    transition: opacity 0.5s ease;
  }

  &:hover {
    .logo {
      opacity: 1.0;
    }
  }
  
  .collapse-to-center {
    @media (max-width: 768px) {
      text-align: center
    }
  }
}


.follow-us {
  margin-top: 2em;
  text-align: right;
  color: #888;

  .icons {
    a {
      margin-left: 1em;
      opacity: 0.5;
      transition: opacity 0.5s ease;

      svg {
        height: 2em;
        width: 2em;
      }

      &:hover {
        opacity: 1.0;
      }
    }
  }
}