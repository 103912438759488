/* styles.css (or your preferred CSS file) */
html, body {
  height: 100%;
  margin: 0;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
/* 
.footer {
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
  position: fixed;
  bottom:0;
  width: 100%;
} */
