$bodycolor: #0c1118;
$btn-primary-color: #0a80bc;
@font-face {
    font-family: 'SFProDisplay-Bold';

    src: url(../../fonts/SFProDisplay-Bold.otf);

  }
  @font-face {
    font-family: 'SFProDisplay-Regular';

    src: url(../../fonts/SFProDisplay-Regular.otf);

  }
  @font-face {
    font-family: 'Inter-Regular';

    src: url(../../fonts/Inter-Regular.ttf);

  }


$font-sf-regular: "SFProDisplay-Regular";
$font-sf-bold: "SFProDisplay-Bold";
$font-inter-regular: "Inter-Regular";


