@import "./../../assets/css/index.scss";
@import "../../assets/css/variables.scss";

.hero {
  background-color: #002939;
  color: white;

  .logo img {
    margin: 5rem 0;

    @include breakpoint(mobile) {
      margin: 1.5rem 0; 
      width: 180px;

      justify-content: center;
      align-items: center;
    }
  }

  .title {
    margin: 3rem 0 3rem 0;
    font-size: 55pt;
    font-family: $font-sf-regular;
    font-weight: 500;
    line-height: 95%; // look a little more compressed

    @include breakpoint(mobile) {
      margin: 3rem 0;
      font-size: 35pt;
    }

    .accent {
      font-size: 110%;
      font-weight: 900;
      font-family: $font-sf-bold;

      padding-bottom: 0.1em;

      background-image: linear-gradient(
        to right,
        rgba(145, 217, 254, 1),
        rgb(14, 126, 183)
      );
      
      background-clip: text;
      color: transparent;
    }
  }

  .copy-text {
    margin: 0 0 3em 0;
  }
}

.herobg {
  width: 100%;
  
  background: linear-gradient(to left, transparent, #0c1016),
    url(./../../assets/images/hero.webp);
  background-size: cover;
  background-position: top right; /* or background-position: 0 0; */
  background-repeat: no-repeat;
  @include breakpoint(tablet) {
    background-size: cover;
  }
  @include breakpoint(mobile) {
    background-image: url(./../../assets/images/hero_mobile.png);
    background-size: cover;
    background-position: center;
  }
  @include breakpoint(largeDesktop) {
    background-position: right;
  }
  @include breakpoint(xxlDesktop) {
    background-position: right;
    width: 100vw;
    
  }

  .herodata {
    width: 100%;
    background-size: cover;
    background: linear-gradient(to right, $bodycolor, #0c101667);
    @include breakpoint(mobile) {
      background-color: #0c101631;

      background-size: cover;
    }
    @include breakpoint(xxlDesktop) {
      
    }
  }
}
